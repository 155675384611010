@import '/styles/_global';
@font-face {font-family: 'Barlow'; src: url('/fonts/Barlow-Medium.ttf') format('truetype'); font-style: normal; font-display: swap;}

.categoryIconParent{
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    overflow: auto;
    width: 100%;

    .plpImage{
        img {
            border-radius: 74px;
        }
    }

    a{
        width: 100%;
        max-width: 130px;
        flex-shrink: 0;
        display: flex;
        margin-right: 0.75rem;
        transition-duration: 0.3s;

        img{
            border-radius: 10px;
            filter: brightness(0.8);
        }

        img:hover{
            filter: brightness(0.5);
        }
    }
}

.categoryIconParent::-webkit-scrollbar{
    width: 0;
    height: 0;
}

.categoryIconLink{
    position: relative;
}

.categoryIconText{
    z-index: 1;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    height: max-content;
    white-space: normal;
    word-wrap: break-word;
    padding: 5px;
    text-align: center;
    font-family: 'Barlow';
   }

@media screen and (max-width: 992px) {
   .categoryIconParent{
        padding-bottom: 26px;

        a{
            max-width: 120px;
        }
   }

   .categoryIconParentPLP{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    column-gap: 20px;
    justify-content: center;
    row-gap: 10px;
    padding-bottom: 1rem;

    a{
        display: block;
        width: 27%;
        transition-duration: 0.3s;

        img{
            border-radius: 74px;
            filter: brightness(0.8);
        }

        img:hover{
            filter: brightness(0.5);
        }
    }
   }
}   